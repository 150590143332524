import { createSelector } from '@reduxjs/toolkit'

const getLineItem = (state, lineItemId) =>
  state.lineItems?.items?.[lineItemId] ?? undefined

export const lineItemsSelector = state => state.lineItems.items

export const lineItemValuesSelector = createSelector(
  [lineItemsSelector],
  items => Object.values(items)
)

export const lineItemKeysSelector = createSelector(
  [lineItemsSelector],
  items => Object.keys(items).map(key => Number(key))
)

export const lineItemsCountSelector = createSelector(
  [lineItemValuesSelector],
  itemsValues => itemsValues.length
)

export const cartSubTotalSelector = createSelector(
  [lineItemValuesSelector],
  items => items.reduce((subTotal, item) => {
    subTotal += parseFloat(item.quantity * item.variant.studentPrice)

    return subTotal.toFixed(2)
  }, 0.0)
)

export const hasItemsSelector = createSelector(
  [lineItemsCountSelector],
  count => count > 0
)

export const hasTotalOutsideLimitSelector = createSelector(
  [
    state => state.currentRegion.bulkEnabled,
    state => state.currentRegion.bulkPurchaseAmountLimit,
    cartSubTotalSelector
  ],
  (bulkEnabled, bulkPurchaseAmountLimit, cartSubTotal) =>
    bulkEnabled && cartSubTotal > bulkPurchaseAmountLimit
)

export const hasRestrictedItemsSelector = createSelector(
  [lineItemValuesSelector],
  items => items.some(item => item.asset.salesRightForCurrentRegionOnly)
)

export const hasFulfillmentErrorStatusSelector = createSelector(
  (state, lineItemId) => getLineItem(state, lineItemId),
  lineItem => !['revoked', 'successful', null]
    .includes(lineItem?.fulfillmentStatus)
)
export const assetLineItemSelector = createSelector(
  [
    lineItemValuesSelector,
    state => state.asset.id
  ],
  (lineItemValues, assetId) => (
    lineItemValues.find(item => item.asset.id === assetId)
  )
)

export const isVariantUpdatedSelector = createSelector(
  [
    state => state.asset.selectedVariant.id,
    assetLineItemSelector,
    state => state.asset.quantity
  ],
  (assetVariantId, lineItem, quantity) => (
    (assetVariantId !== lineItem.variant.id) || (quantity !== lineItem.quantity)
  )
)
