import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'
import { isString } from '@/utils/typeCheck'

const replaceState = (state, { payload }) => {
  const flashMessages = payload[SLICES.flashMessages]

  return flashMessages || state
}

const shortError = message => JSON.stringify(message).substring(0, 360)

const extractErrorFromPayload = payload => {
  if (payload.error) {
    return payload.error
  } else {
    return isString(payload) ? payload : shortError(payload)
  }
}

const handleError = (_state, { payload }) => {
  const text = extractErrorFromPayload(payload)

  return [
    { text, type: 'error' }
  ]
}

const handleErrorAsDialog = (_state, { payload }) => {
  const text = extractErrorFromPayload(payload)

  return [
    { text, type: 'dialog_as_error' }
  ]
}

const handleErrorAsPageLevelMessage = (_state, { payload }) => {
  const text = extractErrorFromPayload(payload)

  return [
    { text, type: 'page_level_message_as_error' }
  ]
}

const handleErrorAsToast = (_state, { payload }) => {
  const text = extractErrorFromPayload(payload)

  return [
    { text, type: 'toast_as_error' }
  ]
}

const handleSuccess = (_state, { payload: message }) => {
  const text = isString(message) ? message : shortError(message)

  return [
    { text, type: 'success' }
  ]
}

const notifyWithToast = (_state, { payload: { message, type } }) => {
  return [
    { text: message, type }
  ]
}

const clearFlashMessages = () => []

const setFlashMessages = (_state, { payload: messages }) => messages

export const reducers = {
  clearFlashMessages,
  handleError,
  handleErrorAsPageLevelMessage,
  handleSuccess,
  notifyWithToast,
  setFlashMessages
}

export const extraReducers = {
  [SHARED_ACTIONS.checkout.switchToBillingAddressStep]: clearFlashMessages,
  [SHARED_ACTIONS.checkout.paypalAuthorizeFailure]: handleErrorAsDialog,
  [SHARED_ACTIONS.checkout.paypalAuthorizeStart]: clearFlashMessages,
  [SHARED_ACTIONS.checkout.paypalCreateFailure]: handleErrorAsDialog,
  [SHARED_ACTIONS.checkout.paypalCreateStart]: clearFlashMessages,
  [SHARED_ACTIONS.checkout.paypalError]: handleErrorAsDialog,
  [SHARED_ACTIONS.checkout.getWatchmanUserBalanceFailure]: handleErrorAsDialog,
  [SHARED_ACTIONS.checkout.getWatchmanUserBalanceStart]: clearFlashMessages,
  [SHARED_ACTIONS.checkout.payWithWatchmanBalanceFailure]: handleError,
  [SHARED_ACTIONS.checkout.paypalExecuteFailure]: handleErrorAsDialog,
  [SHARED_ACTIONS.checkout.paypalExecuteStart]: clearFlashMessages,
  [SHARED_ACTIONS.checkout.updatePendingPurchaseInstitutionSuccess]:
    replaceState,
  [SHARED_ACTIONS.lineItems.createStart]: clearFlashMessages,
  [SHARED_ACTIONS.lineItems.createFailure]: handleError,
  [
    SHARED_ACTIONS.orderHistory.pollBulkDownloadRequestStart
  ]: clearFlashMessages,
  [SHARED_ACTIONS.publisherCampaigns.updateFailure]: handleError,
  [SHARED_ACTIONS.publisherCampaigns.updateStart]: clearFlashMessages,
  [SHARED_ACTIONS.publisherUsers.importUsersFailure]: handleError,
  [SHARED_ACTIONS.publisherUsers.grantAccessFailure]: handleErrorAsToast,
  [SHARED_ACTIONS.publisherUsers.importUsersStart]: clearFlashMessages,
  [SHARED_ACTIONS.publisherUsers.getRowsFailure]: handleError,
  [SHARED_ACTIONS.publisherUsers.getRowsStart]: clearFlashMessages,
  [SHARED_ACTIONS.updateStateFromBackend]: replaceState
}
