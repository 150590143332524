import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  canCheckout: true,
  formattedSavingsTotal: null,
  formattedSubTotalBeforeDiscountOrTax: null,
  fulfillmentStatus: null,
  hasAcceptedOfferTerms: true,
  hasAcceptedTerms: false,
  invalidQuantityMessage: "Please enter a valid quantity.",
  isInvalidPurchase: false,
  isUpdating: false,
  itemsTotalCount: 0,
  offers: {
    enabled: [],
    offerInCart: false
  },
  promoCode: {
    amount: '',
    details: '',
    error: '',
    errorType: null,
    hasAppliedPromoCode: false,
    isUpdating: false,
    name: ''
  },
  savingsTotal: 0,
  subTotal: null,
  total: null
}

const cart = createSlice({
  extraReducers,
  initialState,
  name: SLICES.cart,
  reducers
})

export const { actions } = cart
export default cart
